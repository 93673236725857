import { toggleTab } from "app/pods/ui";
import { getStore } from "app/reduxStore";
// Note open and close don't actually dispatch actions

// Should we represent this as a url instead? Routing with the back button
// will be weird with this scheme

// Using the document.body so that modals etc work

/**
 * So that we're able to log events to amplitude
 * recording duration by recording what time bucket something is under
 */

export default function init() {
  window.addEventListener("blur", function () {
    getStore().dispatch(toggleTab(false));
  });

  window.addEventListener("focus", function () {
    getStore().dispatch(toggleTab(true));
  });
}
