import ReactDOM from "react-dom/client";
import App from "./index";

/* eslint-disable @web-monorepo/no-css-file-imports */
import "@classdojo/web/pods/font/dojoText.css";
import "../app/assets/stylesheets/base.css";
import "@web-monorepo/dojo-islands/fonts/grandstander.css";
/* eslint-enable @web-monorepo/no-css-file-imports */

// take OIDC redirects and take us to the right place (as they are not allowed
// to have hash components:

const root = document.getElementById("root");
if (!root) throw new Error("couldn't find #root element");

ReactDOM.createRoot(root).render(<App />);
