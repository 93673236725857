import { MatomoRouterProvider } from "@web-monorepo/shared/utils/matomo";
import { useSessionFetcher } from "app/pods/auth";
/**
 * Matomo provider with configuration for teach.
 * It should be included within a Router so it has access to location.
 */
export const MatomoConfiguredProvider = ({ children }: { children: JSX.Element }) => {
  const { data: session } = useSessionFetcher({});
  const teacher = session && session.teacher;

  return (
    <MatomoRouterProvider
      userId={teacher?._id}
      urlBase="https://teach.classdojo.com"
      domains={["*.teach.classdojo.com"]}
    >
      {children}
    </MatomoRouterProvider>
  );
};
