import { I18nextContainer, initialize } from "@web-monorepo/i18next";
import { sessionLocaleContext } from "./sessionLocale";
import { useState } from "react";
import { LoadingMojo } from "@classdojo/web";
const I18N_NAMESPACES = ["dojo.common", "dojo.teacher_web", "dojo.dojo_islands_activities"];

initialize({ ns: I18N_NAMESPACES });

type I18NContainerTeachProps = {
  locale: string | undefined;
  children: JSX.Element;
};

const I18NContainerTeach = ({ locale, children }: I18NContainerTeachProps) => {
  const [sessionLocale, setSessionLocale] = useState(navigator.language);

  return (
    <sessionLocaleContext.Provider value={[sessionLocale, setSessionLocale]}>
      <I18nextContainer
        locale={locale || sessionLocale}
        namespaces={I18N_NAMESPACES}
        fallback={<LoadingMojo noOverlay border />}
      >
        {children}
      </I18nextContainer>
    </sessionLocaleContext.Provider>
  );
};

export default I18NContainerTeach;
