import { CheckboxRadioGroup } from "@classdojo/web/nessie";
import { useContext } from "react";
import { ColorThemeContext } from "@classdojo/web/nessie/components/useColorTheme";

export const ThemesSection = () => {
  const { setNewTheme, mode } = useContext(ColorThemeContext);
  const themes = ["nessie", "dds-dark", "dds-light", "dds-debug"].map((type) => {
    return {
      value: type,
      labelText: type,
      checked: mode === type,
      onChange: () => setNewTheme(type),
    };
  });
  return (
    <div
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        "* label": {
          color: "dt_taro90",
        },
      }}
    >
      <CheckboxRadioGroup
        groupType="radio"
        groupLabel="Theme picker"
        groupName="toggle themes"
        inputs={themes}
        sx={{ marginBottom: "l" }}
      />
    </div>
  );
};
