import env from "../utils/env";

if (env.shouldThrowPropTypesErrors) {
  const _console = {
    // eslint-disable-next-line no-console
    error: console.error.bind(console),
  };

  // eslint-disable-next-line no-console
  console.error = (message, ...args) => {
    // ignore react-router warning caused by hot-reloading
    if (/You cannot change <Router routes>/gi.test(message)) {
      return;
    }

    const isPropTypesError = /(Invalid prop|Failed prop type)/gi.test(message);
    if (isPropTypesError) {
      throw new Error([message, ...args].join(" "));
    }

    _console.error(message, ...args);
  };
}
