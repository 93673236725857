import throttle from "lodash/throttle";
import env from "../utils/env";

const _console = {
  // eslint-disable-next-line no-console
  error: console.error.bind(console),
};

let _numberOfWarnings = 0;

const flushWarnings = throttle(
  (message, ...args) => _console.error(`${_numberOfWarnings} time(s): ${message}`, args),
  10000,
);

if (!env.isTesting) {
  // eslint-disable-next-line no-console
  console.error = (message, ...args) => {
    const isUnknownPropWarn = /^Warning:(.*)For details, see https:\/\/fb.me\/react-unknown-prop/.test(message);

    if (isUnknownPropWarn) {
      ++_numberOfWarnings;
      flushWarnings(message, ...args);
      return;
    }

    _console.error(message, ...args);
  };
}
