import * as logClient from "@classdojo/log-client";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const useLogMarketingPageview = (hasFinishedFetchingSession: boolean) => {
  const [searchParams] = useSearchParams();
  const [loggedPageview, setLoggedPageview] = useState(false);

  useEffect(() => {
    const utmCampaign = searchParams.getAll("utm_campaign").join("");
    if (utmCampaign && hasFinishedFetchingSession && !loggedPageview) {
      logClient.logEvent({
        eventName: `pageview.referrer_link`,
        eventValue: utmCampaign,
        metadata: Object.fromEntries(searchParams),
      });
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setLoggedPageview(true);
    }
  }, [searchParams, hasFinishedFetchingSession, loggedPageview]);
};

export default useLogMarketingPageview;
