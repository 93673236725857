import * as logClient from "@classdojo/log-client";
import { LoadingMojo, LoggerContextSetter } from "@classdojo/web";
import useWatch from "@classdojo/web/hooks/useWatch";
import { NOOP, WAITING_FOR_DEPENDENCIES } from "@web-monorepo/shared/reactQuery";
import useLogMarketingPageview from "@web-monorepo/shared/utils/useLogMarketingPageview";
import { useSessionFetcher } from "app/pods/auth";
import { useEnsureFeatureSwitches, useFetchedFeatureSwitchesWithOverrides } from "app/pods/featureSwitches";
import { SCHOOL_FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";
import { useFetchedTeacher } from "app/pods/teacher";
import { setNewSignup } from "app/pods/transient";
import { useUserConfigFetcher } from "app/pods/userConfig";
import pubnub from "app/pubnub";
import { useDispatch } from "app/utils/reduxHooks";
import { ReactElement, cloneElement, memo, useCallback } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

/* eslint-disable-next-line @web-monorepo/no-css-file-imports */
import "@classdojo/web/pods/font/dojoText.css";
/* eslint-disable-next-line @web-monorepo/no-css-file-imports */
import "@web-monorepo/dojo-islands/fonts/grandstander.css";
import { navigate } from "@web-monorepo/shared/router/reactRouterNavigate";
import { useSchoolsFetcher } from "app/pods/school";
import { useTeacherSwitchSchoolOperation } from "app/pods/schoolTeachers";
import { setUser } from "@web-monorepo/telemetry";
import I18NContainer from "app/I18NContainer";

type ComponentWithChildProps = {
  children: ReactElement;
};

export type ApplicationContainerProps = ComponentWithChildProps;

function useEnsureInSelectedSchool() {
  const loc = useLocation();
  // Regular expression pattern
  const regex = /\/schools\/([a-f0-9]+)\/(.*)/;

  // Test if the string matches the pattern
  // We have to use regex because useParam doesn't work in this case
  const match = loc.pathname.match(regex);
  const isSelecting = match ? ["select", "join", "transfer_personal_classes"].includes(match[2]) : null;

  const schoolId = match ? match[1] : null;

  const [searchParams, setSearchParams] = useSearchParams();

  const teacher = useFetchedTeacher();
  const selectPersonalClassesSearchParam = searchParams.get("select") === "personal_classes";
  const selectPersonalClasses = teacher && selectPersonalClassesSearchParam;

  const {
    data: schools = [],
    isFetching: schoolsLoading,
    isInitialLoading: schoolsNotFetched,
  } = useSchoolsFetcher(schoolId ? {} : NOOP);

  const selectedSchoolId = schools.find((school) => school.selected)?.schoolId;
  const employedAtSchool = schools.some((school) => school.schoolId === schoolId);

  const needToChangeSchools =
    !isSelecting &&
    teacher &&
    !schoolsNotFetched &&
    !selectPersonalClasses &&
    schoolId &&
    selectedSchoolId !== schoolId;

  const { mutate: joinSchool, isLoading: isChangingSchools, isSuccess } = useTeacherSwitchSchoolOperation();

  const switchSchool = useCallback(
    (schoolId: string) =>
      teacher &&
      teacher.emailAddress &&
      joinSchool({
        path: {
          schoolId: schoolId!,
        },
      }),
    [joinSchool, teacher],
  );

  useWatch([isSuccess, selectPersonalClassesSearchParam], ([isSuccess]) => {
    if (isSuccess && selectPersonalClassesSearchParam) {
      setSearchParams(
        (searchParams) => {
          const nextSearchParams = new URLSearchParams(searchParams);
          nextSearchParams.delete("select");
          return nextSearchParams;
        },
        { replace: true },
      );
    }
  });

  useWatch(
    [
      schoolId,
      switchSchool,
      needToChangeSchools,
      employedAtSchool,
      schoolsLoading,
      navigate,
      schoolsNotFetched,
    ] as const,
    ([schoolId, switchSchool, needToChangeSchools, employedAtSchool, schoolsLoading, navigate, schoolsNotFetched]) => {
      if (schoolsNotFetched) return;
      if (!schoolId) return;
      if (!needToChangeSchools) return;
      if (schoolsLoading) return;
      if (employedAtSchool) {
        // This switch is for the case where a teacher has school A as active but it's loading a url with school B
        switchSchool(schoolId!);
      } else {
        setTimeout(() => navigate("/"), 0);
      }
    },
  );

  useWatch([selectPersonalClasses], () => {
    if (!selectPersonalClasses) return;
    switchSchool("personal_classes");
  });
  return {
    inSelectedSchool: isSelecting || (!schoolsLoading && !needToChangeSchools && !isChangingSchools),
  };
}

// eslint-disable-next-line react-refresh/only-export-components
const ApplicationContainer = (props: ApplicationContainerProps) => {
  const dispatch = useDispatch();
  const { error: sessionError, data: session } = useSessionFetcher({});
  const hasFinishedFetchingSession = !!(sessionError || session);
  const authenticated = hasFinishedFetchingSession && !!session && !!session.teacher;

  const teacher = session?.teacher;

  const [searchParams] = useSearchParams();

  const { inSelectedSchool } = useEnsureInSelectedSchool();

  useWatch([dispatch, searchParams] as const, ([dispatch, searchParams]) => {
    if (searchParams.get("signupSource")) {
      dispatch(setNewSignup());
    }
  });

  const teacherId = teacher?._id;

  useWatch(teacherId, (teacherId) => {
    if (teacherId) {
      pubnub.init(teacherId);
      //datadogRUMClient.setUser({ id: teacherId });
      logClient.setEntityId(teacherId);
    }

    setUser(teacherId ? { id: teacherId, type: "teacher" } : null);
  });

  useLogMarketingPageview(hasFinishedFetchingSession);

  if (!hasFinishedFetchingSession || !inSelectedSchool) {
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  const _children = (props.children && cloneElement(props.children)) || null;

  const EnsureConfigContainer = authenticated ? EnsureUserConfigContainer : EnsurePublicUserConfigContainer;

  return (
    <I18NContainer locale={teacher?.locale}>
      <EnsureConfigContainer>
        <EnsureLoggerContext>{_children}</EnsureLoggerContext>
      </EnsureConfigContainer>
    </I18NContainer>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default memo(ApplicationContainer);

type EnsurePublicUserConfigContainerProps = ComponentWithChildProps;

//
// Trigger more initializing for the stuff that we need when we don't have a session
//
// eslint-disable-next-line react-refresh/only-export-components
const EnsurePublicUserConfigContainer = ({ children }: EnsurePublicUserConfigContainerProps) => {
  const { ready: isFeatureSwitchesReady } = useEnsureFeatureSwitches();

  if (!isFeatureSwitchesReady) {
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  return children;
};

type EnsureUserConfigContainerProps = ComponentWithChildProps;
// Trigger more initializing for the stuff that we need once we know the session
//
// eslint-disable-next-line react-refresh/only-export-components
const EnsureUserConfigContainer = ({ children }: EnsureUserConfigContainerProps) => {
  // Making sure that ANYBODY down the tree after here can use useFetchedTeacher
  const teacher = useFetchedTeacher();

  const { data: userConfig } = useUserConfigFetcher(teacher ? {} : WAITING_FOR_DEPENDENCIES);
  const { ready: isFeatureSwitchesReady } = useEnsureFeatureSwitches();

  const finishedFetchingUserConfig = !!userConfig;
  const finishedFetchingTeacher = !!teacher;
  const isTeacherSession = teacher;

  // Show loading until we ensure we have all we need
  // The isTeacherSession is a tricky one, we show loading if it's not because another
  // container is going to do a redirect if the session is not a parent one.
  if (!finishedFetchingUserConfig || !isFeatureSwitchesReady || !finishedFetchingTeacher || !isTeacherSession) {
    return (
      <div>
        <LoadingMojo noOverlay border />
      </div>
    );
  }

  return children;
};

type EnsureLoggerContextProps = ComponentWithChildProps;

// eslint-disable-next-line react-refresh/only-export-components
const EnsureLoggerContext = ({ children }: EnsureLoggerContextProps) => {
  const teacher = useFetchedTeacher();
  const fetchedFeatureSwitches = useFetchedFeatureSwitchesWithOverrides();
  const entityType = teacher ? "teacher" : "preSignUp";

  // provide default school feature switch values of off for teachers that are not part of a school
  // and didnt fetch those switches
  Object.values(SCHOOL_FEATURE_SWITCHES).forEach((schoolFeatureSwitch) => {
    if (!fetchedFeatureSwitches[schoolFeatureSwitch]) {
      fetchedFeatureSwitches[schoolFeatureSwitch] = "off";
    }
  });

  return (
    <>
      <LoggerContextSetter prefix="teach" entityType={entityType} fetchedFeatureSwitches={fetchedFeatureSwitches} />
      {children}
    </>
  );
};
