import env from "../utils/env";

if (!env.isTesting) {
  const _console = {
    // eslint-disable-next-line no-console
    error: console.error.bind(console),
  };

  let showedPropTypesCallWarn = false;

  // eslint-disable-next-line no-console
  console.error = (message, ...args) => {
    const isPropTypesCallWarn = /See https:\/\/fb.me\/react-warning-dont-call-proptypes for details\.$/.test(message);

    if (isPropTypesCallWarn) {
      if (!showedPropTypesCallWarn) {
        // eslint-disable-next-line no-console
        console.warn(message, ...args);
        showedPropTypesCallWarn = true;
      }
      return;
    }

    _console.error(message, ...args);
  };
}
