import { useSessionFetcher } from "app/pods/auth";

export const useIsClassDojoUser = () => {
  const { data: session } = useSessionFetcher({});

  const userEmail: string | undefined = session && session.teacher && session.teacher.emailAddress;
  return userEmail ? userEmail.includes("@classdojo.com") : false;
};

export { EventRecorder, DevTools, DevToolsSettings } from "@web-monorepo/shared/devTools";
