import { ClassNames, GlobalCSS } from "@classdojo/web/nessie/stylingLib";
// eslint-disable-next-line @web-monorepo/no-css-file-imports
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

//Top level toast container for all of teach
// 1. If you pass a component to toast(component) this will render it
// 2. If you pass a string the options override the defaults in this container, e.g. below
//  toast(translate("dojo.teacher_web:calendar_events.deleted_toast"), {
//       position: "bottom-right",
//       className: "success-toast-body",
//       closeButton: true,
//     });

type ToastRenderingContainerProps = {
  children: React.ReactElement;
};
const ToastRenderingContainer = ({ children }: ToastRenderingContainerProps) => {
  return (
    <>
      {children}
      <ClassNames>
        {({ css }) => (
          <ToastContainer
            position="top-center"
            autoClose={3500}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            closeButton={false}
            sx={{ width: "unset" }}
            // we pass toast a component, not a string to fully control UI. this css removes some default background
            toastClassName={css({ background: "none", boxShadow: "none" })}
          />
        )}
      </ClassNames>
      <GlobalCSS
        styles={{
          ".success-toast-body": {
            backgroundColor: "#BDFF7A",
            color: "#423D5D",
            borderRadius: "5px",
            fontWeight: "bold",
            textAlign: "center",
          },
          ".warn-toast-body": {
            backgroundColor: "#FFF3E1",
            color: "#423D5D",
            borderRadius: "5px",
            fontWeight: "bold",
            textAlign: "center",
          },
        }}
      />
    </>
  );
};

export default ToastRenderingContainer;
