import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { useIsDevToolsEnabled } from "@web-monorepo/shared/devTools/devToolsSettings";
import { makeMemberQuery } from "@web-monorepo/shared/reactQuery";
import { localStorage } from "@web-monorepo/safe-browser-storage";
import { useIsClassDojoUser } from "app/pods/devTools";

type AlertDismissalMetadata = APIResponse<"/api/alertDismissalMetadata", "get">;
export type PopulatedAlertDismissalMetadata = Extract<AlertDismissalMetadata, { entityId: string }>;

export function isPopulatedAlertDismissalMetadata(
  data: AlertDismissalMetadata,
): data is PopulatedAlertDismissalMetadata {
  return "entityId" in data;
}

export const useAlertDismissalMetadataFetcher = makeMemberQuery({
  path: `/api/alertDismissalMetadata`,
  fetcherName: "alertDismissalMetadata",
});

const useSkipDismissalChecks = () => {
  const isClassdojoTeacher = useIsClassDojoUser();
  if (isClassdojoTeacher && window.location?.href?.includes("skipDismissals=true")) {
    return true;
  }

  return false;
};

export const useDismissalsOrOverrides = () => {
  const dismissalsFetcher = useAlertDismissalMetadataFetcher({});
  const devToolsEnabled = useIsDevToolsEnabled();
  const skipDismissals = useSkipDismissalChecks();
  if (skipDismissals) {
    return { data: {}, isFetching: false };
  }

  const dismissalsOverrides = getAlertDismissalOverrides();

  if (devToolsEnabled && dismissalsOverrides?.entityId) {
    return { data: dismissalsOverrides, isFetching: false };
  }

  return dismissalsFetcher;
};

export const useWithinDismissalThreshold = (alertCardId: string, maxDismissals = 3) => {
  const { data: dismissals, isFetching: dismissalsLoading } = useDismissalsOrOverrides();

  if (dismissalsLoading) return false;

  if (!dismissals || !isPopulatedAlertDismissalMetadata(dismissals)) return true;

  const previousDismissals = dismissals?.cardDismissals && dismissals?.cardDismissals[alertCardId];
  if (previousDismissals && previousDismissals.length >= maxDismissals) return false;
  return true;
};

export const setAlertDismissalOverrides = (alertDismissalOverrides: AlertDismissalMetadata | Record<string, never>) => {
  localStorage.setItem("devTools.alertDismissalOverrides", JSON.stringify(alertDismissalOverrides));
};

export const getAlertDismissalOverrides = (): any => {
  try {
    return JSON.parse(localStorage.getItem("devTools.alertDismissalOverrides") || "{}");
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    return {};
  }
};

export const removeAlertDismissalOverrides = () => {
  try {
    return localStorage.removeItem("devTools.alertDismissalOverrides");
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    return {};
  }
};
