import * as logClient from "@classdojo/log-client";
import { useSessionFetcher } from "app/pods/auth";
import { DevToolsSettings } from "app/pods/devTools";
import { setFeatureSwitchesConfig } from "app/pods/featureSwitches";
import {
  TEACHER_FEATURE_SWITCHES,
  SCHOOL_FEATURE_SWITCHES,
  SESSION_FEATURE_SWITCHES,
} from "app/pods/featureSwitches/constants";
import { useFetchedTeacher } from "app/pods/teacher";
import getCountryCode from "app/utils/getCountryCode";
export { FEATURE_SWITCHES } from "app/pods/featureSwitches/constants";

const QUERY_PARAMS = [
  "teacherId",
  "teacherCountryCode",
  "teacherSwitches",
  "sessionId",
  "sessionSwitches",
  "schoolId",
  "schoolSwitches",
  "schoolCountryCode",
  "schoolIsMentor",
  "schoolLocale",
  "teacherIsDojoian",
  "teacherIsMentor",
  "teacherLocale",
  "appVersion",
] as const;

export type QueryStringParams = { [key in (typeof QUERY_PARAMS)[number]]?: string | boolean };

// This hook is called when loading the feature switches and it returns the query string parameters
// to be sent to the API.
// If no parameter values are available yet, you can return `null` to delay the API call until
// they become available in a later render cycle.
const useBuildFeatureSwitchesQueryParamsHook = () => {
  const teacher = useFetchedTeacher();

  const { error: sessionError, data: session } = useSessionFetcher({});
  const hasFetchedSession = !!(sessionError || session);
  const isDojoian = session && session.isDojoian;
  const sessionId = logClient.getSessionId();

  if (!hasFetchedSession) return null;

  const queryStringParams: QueryStringParams = {};

  queryStringParams.appVersion = window.appVersion;

  if (Object.values(TEACHER_FEATURE_SWITCHES).length && teacher) {
    // only load feature switches associated with parent if user is logged in
    queryStringParams.teacherId = teacher._id;
    queryStringParams.teacherCountryCode = getCountryCode(teacher);
    queryStringParams.teacherSwitches = encodeURIComponent(Object.values(TEACHER_FEATURE_SWITCHES).join(","));
    queryStringParams.teacherIsMentor = Boolean(teacher.isMentor);
    queryStringParams.teacherLocale = teacher.locale || "en-US";
    if (isDojoian) {
      queryStringParams.teacherIsDojoian = true;
    }
  }

  // We choose 'session' as the name for the group when reaching the case of a logged out user.
  // Session id always will exist, either the session id coming from the cookie (resolved by the log client)
  // or passed as argument from external site (and set using log client).
  if (Object.values(SESSION_FEATURE_SWITCHES).length && sessionId) {
    queryStringParams.sessionId = sessionId;
    queryStringParams.sessionSwitches = encodeURIComponent(Object.values(SESSION_FEATURE_SWITCHES).join(","));
  }

  if (Object.values(SCHOOL_FEATURE_SWITCHES).length && teacher && teacher.schoolId) {
    queryStringParams.schoolId = teacher.schoolId;
    queryStringParams.schoolSwitches = encodeURIComponent(Object.values(SCHOOL_FEATURE_SWITCHES).join(","));
    queryStringParams.schoolCountryCode = getCountryCode(teacher);
    // allow school-based switches to also filter by language/locale/mentor status
    queryStringParams.schoolLocale = teacher.locale;
    queryStringParams.schoolIsMentor = Boolean(teacher.isMentor);
  }

  return queryStringParams;
};

export function setAppFeatureSwitchesConfig() {
  setFeatureSwitchesConfig({
    featureSetIdLocalStorageKey: `teach/featureSwitches/featureSetId`,
    useBuildFeatureSwitchesQueryParamsHook,
    useFeatureSwitchOverridesHook: () => {
      const { featureSwitchOverrides } = DevToolsSettings.useFeatureSwitchOverrides();
      return featureSwitchOverrides;
    },
    featureSwitchesFetcherQueryParams: QUERY_PARAMS.slice(),
  });
}
