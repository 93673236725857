import { createContext, useContext } from "react";

export const sessionLocaleContext = createContext<[string, (locale: string) => void] | null>(null);

export const useSessionLocale = (): [string, (locale: string) => void] => {
  const context = useContext(sessionLocaleContext);
  if (context === null) {
    throw new Error("useSessionLocale must be used within ApplicationContainer");
  }
  return context;
};
